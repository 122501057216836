import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  // Landlords
  {
    path: '/landlords',
    name: 'Landlords',
    component: () => import('../views/Landlords.vue'),
  },
  {
    path: '/agency-fees-&-charges',
    name: 'Agency Fees & Charges',
    component: () => import('../views/AgencyFeesAndCharges.vue'),
    meta: {
      hide: true,
    },
  },
  // Tenants
  {
    path: '/tenants',
    name: 'Tenants',
    component: () => import('../views/Tenants.vue'),
  },
  {
    path: '/properties-for-rent',
    name: 'Properties For Rent',
    component: () => import('../views/PropertiesForRent.vue'),
  },
  {
    path: '/forms/queensland',
    name: 'Queensland Forms',
    component: () => import('../views/QueenslandForms.vue'),
  },
  {
    path: '/forms/queensland/appointment-or-reappointment-of-agent',
    name: 'Appointment or Reappointment of Agent',
    component: () => import('../views/Queensland/Form6.vue'),
  },
  {
    path: '/forms/queensland/entry-notice',
    name: 'Entry Notice',
    component: () => import('../views/Queensland/Form9.vue'),
  },
  {
    path: '/forms/queensland/entry-notice-rooming-accommodation',
    name: 'Entry Notice Rooming Accommodation',
    component: () => import('../views/Queensland/FormR9.vue'),
  },
  {
    path: '/forms/queensland/tenancy-agreement',
    name: 'Tenancy Agreement',
    component: () => import('../views/Queensland/TenancyAgreement.vue'),
  },
  {
    path: '/forms/queensland/tenancy-application',
    name: 'Tenancy Application',
    component: () => import('../views/Queensland/TenancyApplication.vue'),
  },
  {
    path: '/forms/queensland/entry-condition-report',
    name: 'Entry Condition Report',
    component: () => import('../views/Queensland/EntryConditionReport.vue'),
  },
  {
    path: '/forms/queensland/maintenance-request',
    name: 'Maintenance Request',
    component: () => import('../views/Queensland/MaintenanceRequest.vue'),
  },
  {
    path: '/forms/queensland/notice-of-leave',
    name: 'Notice Of Leave',
    component: () => import('../views/Queensland/NoticeOfLeave.vue'),
  },
  {
    path: '/forms/queensland/exit-condition-report',
    name: 'Exit Condition Report',
    component: () => import('../views/Queensland/ExitConditionReport.vue'),
  },
  {
    path: '/forms/new-south-wales',
    name: 'New South Wales Forms',
    component: () => import('../views/NewSouthWalesForms.vue'),
  },
  {
    path: '/forms/new-south-wales/tenancy-agreement',
    name: 'Tenancy Agreement',
    component: () => import('../views/NewSouthWales/TenancyAgreement.vue'),
  },
  {
    path: '/forms/new-south-wales/tenancy-application',
    name: 'Tenancy Application',
    component: () => import('../views/Queensland/TenancyApplication.vue'),
  },
  {
    path: '/forms/new-south-wales/condition-report',
    name: 'Condition Report',
    component: () => import('../views/NewSouthWales/ConditionReport.vue'),
  },
  {
    path: '/forms/new-south-wales/maintenance-request',
    name: 'Maintenance Request',
    component: () => import('../views/Queensland/MaintenanceRequest.vue'),
  },
  {
    path: '/forms/new-south-wales/notice-of-leave',
    name: 'Notice Of Leave',
    component: () => import('../views/NewSouthWales/NoticeOfLeave.vue'),
  },
  // About Us
  {
    path: '/the-agency',
    name: 'The Agency',
    component: () => import('../views/About.vue'),
  },
  // For Sale
  /* {
    path: '/properties-for-sale',
    name: 'Properties For Sale',
    component: () => import('../views/PropertiesForSale.vue'),
  },
  {
    path: '/sold-properties',
    name: 'Sold Properties',
    component: () => import('../views/PropertiesSold.vue'),
  },
  {
    path: '/open-homes',
    name: 'Open Homes',
    component: () => import('../views/InspectionTimes.vue'),
  }, */
  // Sell
  /* {
    path: '/properties-sold',
    name: 'Properties Sold',
    component: () => import('../views/PropertiesSold.vue'),
  },
  {
    path: '/testimonials',
    name: 'Testimonials',
    component: () => import('../views/Testimonials.vue'),
  },
  */
  // Contact
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: () => import('../views/ContactUs.vue'),
  },
  // Other Pages
  {
    path: '/listings/:id',
    name: 'Listing',
    meta: {
      hide: true,
    },
    component: () => import('../views/ListingDetails.vue'),
  },
  {
    path: '/agents/:id',
    name: 'Agent Profile',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfile.vue'),
  },
  {
    path: '/blogs/:id',
    name: 'Blog Details',
    meta: {
      hide: true,
    },
    component: () => import('../views/BlogDetails.vue'),
  },
  // Not being used
  {
    path: '/forms/lease-appraisal',
    name: 'Lease Appraisal',
    component: () => import('../views/RentalAppraisal.vue'),
  },
  {
    path: '/forms/sales-appraisal',
    name: 'Sales Appraisal',
    component: () => import('../views/SalesAppraisal.vue'),
  },
  {
    path: '/tv-display/sales',
    name: 'TV Display Sales',
    component: () => import('../views/TVDisplaySales.vue'),
    meta: {
      hide: true,
      hideAncillaries: true, // Disables the header and footer
    },
  },
  {
    path: '/tv-display/rentals',
    name: 'TV Display Rentals',
    component: () => import('../views/TVDisplayRentals.vue'),
    meta: {
      hide: true,
      hideAncillaries: true, // Disables the header and footer
    },
  },
  // Page Ids
  {
    path: '/listings/:id/tenanacy-application',
    name: 'Tenanacy Application',
    meta: {
      hide: true,
    },
    component: () => import('../views/Queensland/ListingTenanacyApplication.vue'),
  },
  {
    path: '/listings/:id/make-an-offer',
    name: 'Make an Offer',
    meta: {
      hide: true,
    },
    component: () => import('../views/ListingMakeAnOffer.vue'),
  },
  {
    path: '/profiles/:id',
    name: 'Profile',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfile.vue'),
  },
  {
    path: '/profiles/:id/properties',
    name: 'Profile Listings',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfileListings.vue'),
  },
  {
    path: '/profiles/:id/properties/sold',
    name: 'Profile Sold Listings',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfileListingsSold.vue'),
  },
  {
    path: '/profiles/:id',
    name: 'Profile',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfile.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/terms-of-use',
    name: 'Terms Of Use',
    component: () => import('../views/TermsOfUse.vue'),
  },
  {
    path: '/sitemap',
    name: 'Sitemap',
    component: () => import('../views/Sitemap.vue'),
  },
  {
    path: '/unsubscribe',
    name: 'Unsubscribe',
    component: () => import('../views/Unsubscribe.vue'),
  },
  {
    path: '/*',
    name: 'Not Found',
    meta: {
      hide: true,
    },
    component: () => import('../views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
});

export default router;
