
import { ImageSourceSet } from 'client-website-ts-library/types';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Masthead extends Vue {
    @Prop()
    private readonly darken!: boolean;

    @Prop({ default: false })
    private readonly extraDark!: boolean;

    @Prop({ default: '/assets/images/photos/background.webp' })
    private readonly image!: string | ImageSourceSet;

    @Prop()
    private readonly relativeContent!: boolean;
}
